// @use '@circutor/ui/scss/common/cds-circutor-bundle';

@use '@circutor/ui/scss/common/cds-bundle' with (
  $config-brand: 'circutor',
  $config-resources-path: '../../resources',
  $config-icons: 's',
  $_layout-header-height: 4.5rem
);


@use '@circutor/ui/scss/01-settings/_cds-config-typography' as *;

/* Reset all default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 100%;
  vertical-align: baseline;
  color: var(--cds-color-text-default);
  background-color: transparent;
  border: none;
  outline: none;
  font-family: $config-font-family-400;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

/* Set default styles for headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  border: none;
}

/* Set default styles for lists */
ul,
ol {
  list-style: none;
}

/* Set default styles for links */
a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: 700;
  color: #42505c;
  font-family: $config-font-family-700;
}

/* AntDesign styles */
.ant-dropdown-menu-item {
  height: 2.5rem;
  display: flex !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.ant-menu-horizontal .ant-menu-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

main .ant-card .ant-card-body {
  padding: 1rem 0.5rem;
}

nav button.ant-btn-primary:hover {
  background-color: #e1e1e1 !important;
  /* Change to your desired background color */
  color: #20262c !important;
  /* Optional: Change the text color on hover */
}

nav button.ant-btn-primary[class*='active']:hover {
  background-color: black !important;
  color: white !important;
}

.ant-badge > svg {
  position: absolute;
  z-index: 10;
  fill: var(--cds-color-accent-primary-main);
  top: 4px;
  left: 11px;
  filter: contrast(0.5);
}

.ant-btn > svg {
  display: none;
}

.ant-badge svg {
  display: none;
}

.ant-list-item-meta-avatar {
  align-self: center;
}

.ant-picker,.ant-picker-panel-layout,.ant-menu-sub,.ant-card,.ant-input-outlined:not(.ant-input-disabled),.ant-select-selector,.ant-input-number {
  background-color: var(--cds-color-background-default) !important;
}

.ant-menu-light {
  background-color: transparent;
}

/* Remove background color when the dropdown is clicked */
.ant-select-focused .ant-select-selection {
  background: transparent !important;
}

header ul .ant-menu-title-content {
  color: var(--cds-color-text-subtle);
  display: none;
  height: 2.5rem;
}

.ant-menu-submenu-arrow {
  color: #737373 !important;
  transform: rotate(90deg) !important;
}

header ul.ant-menu-root {
  display: flex;
  border-bottom: none;
  justify-content: flex-end;
  align-items: center;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
}

.ant-form-item .ant-form-item-explain > .ant-form-item-explain-error {
  text-align: end !important;
}

@media only screen and (min-width: 1025px) {
  header ul.ant-menu-root {
    width: auto;
    flex: 1;
  }

  header ul .ant-menu-title-content {
    display: flex;
  }

  .simplebar-content a button.ant-btn > svg {
    display: block;
    position: absolute;
    left: 4px;
    top: 3px;
    filter: contrast(0.6);
  }

  .ant-badge > svg {
    display: block;
  }

  nav > button.ant-btn {
    padding-left: 45px !important;
  }
}

body {
  overflow: hidden;
}

nav .simplebar-content {
  display: flex;
}

nav .simplebar-content > .ant-badge:hover button {
  background-color: #e1e1e1 !important;
  color: #20262c !important;
  
}

.simplebar-track.simplebar-horizontal {
  bottom: -9px;
}

.simplebar-scrollbar {
  background-color: #c0c0c0;
  height: 4px;
  border-radius: 4px;
  bottom: 1px !important;
  top: 6px !important;
}

.simplebar-scrollbar:before {
  background-color: transparent !important;
}

.simplebar-scrollable-x {
  top: 0;
}

.simplebar-track.simplebar-vertical {
  width: 6px !important;
  border-radius: 3px;
}
